/**
 * Server environment will read process.env to read config values
 * Client environment will read window.__INITIAL_ENV__ rendered by server html-index-generator
 *
 * WARNING: do not use `webpack.DefinePlugin` in server build as this renders values in place of `process.env` reference which prevents the server code to read system environment variables
 */
const ENV = process.env;
// NODE_ENV - defines production build and production code execution
export const isProduction: boolean = ENV.NODE_ENV === "production";
export const isDevelopment: boolean = ENV.NODE_ENV === "development";
if (!isProduction && !isDevelopment) {
    throw new Error("env: NODE_ENV is not properly defined");
}
// EXEC_ENV - defines execution environment (browser or server)
export const isBrowser: boolean = ENV.EXEC_ENV === "browser";
export const isServer: boolean = ENV.EXEC_ENV === "server";
if (!isBrowser && !isServer) {
    throw new Error("env: EXEC_ENV is not properly defined");
}

declare global {
    interface Window {
        __INITIAL_ENV__: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

const UNIVERSAL_ENV = isBrowser ? window.__INITIAL_ENV__ : process.env;

/**
 * Build image environment
 */
export const buildImageTag: string = UNIVERSAL_ENV.BUILD_IMAGE_TAG;
/**
 * Main RP variables
 */
// VALIDATE_AB_TEST_DOMAIN - Enable or disable ab test domain validation
export const validateAbTestDomain: boolean = UNIVERSAL_ENV.VALIDATE_AB_TEST_DOMAIN === "true";

// RP_COMMON_URL - defines current RP site used to display full URL
export const rpCommonUrl: string = (UNIVERSAL_ENV.RP_COMMON_URL ?? null) as string;
if (rpCommonUrl == null) {
    throw new Error("env: RP_COMMON_URL is not defined");
}
// RP_SERVER_V1_API_URL - defines current RP API site used to fetch backend data for path /api/:endpoint
export const rpServerV1ApiUrl: string = (UNIVERSAL_ENV.RP_SERVER_V1_API_URL ?? null) as string;
if (isServer && rpServerV1ApiUrl == null) {
    throw new Error("env: RP_SERVER_V1_API_URL is not defined for server environment");
}
// RP_SERVER_GLUE_API_URL - defines current RP API site used to fetch backend data for path /api/s/v2/:endpoint
export const rpServerGlueApiUrl: string = (UNIVERSAL_ENV.RP_SERVER_GLUE_API_URL ?? null) as string;
if (isServer && rpServerGlueApiUrl == null) {
    throw new Error("env: RP_SERVER_GLUE_API_URL is not defined for server environment");
}
// RP_SERVER_V2_API_URL - defines current RP API site used to fetch backend data for path /api/v2/:endpoint
export const rpServerV2ApiUrl: string = (UNIVERSAL_ENV.RP_SERVER_V2_API_URL ?? null) as string;
if (isServer && rpServerV2ApiUrl == null) {
    throw new Error("env: RP_SERVER_V2_API_URL is not defined for server environment");
}
// RP_SERVER_INTERNAL_API_URL - defines current RP internal API site used to fetch backend data from special internal API (optimized execution)
export const rpServerInternalApiUrl: string = (UNIVERSAL_ENV.RP_SERVER_INTERNAL_API_URL ?? null) as string;
if (isServer && rpServerInternalApiUrl == null) {
    throw new Error("env: RP_SERVER_INTERNAL_API_URL is not defined for server environment");
}
// RP_SERVER_MP_API_URL - defines current MP (marketplace) API site used to fetch backend data for path /api/marketplace/:endpoint
export const rpServerMpApiUrl: string = (UNIVERSAL_ENV.RP_SERVER_MP_API_URL ?? null) as string;
if (isServer && rpServerMpApiUrl == null) {
    throw new Error("env: RP_SERVER_MP_API_URL is not defined for server environment");
}
// RP_CLIENT_API_URL - defines current RP API site used to fetch backend data (client side)
export const rpClientApiUrl: string | null = UNIVERSAL_ENV.RP_CLIENT_API_URL ?? null;
// RP_COOKIE_DOMAIN - defines cookie domain to add while fetching data internally (rpbi does not provide its own cookie domain)
export const rpInternalApiCookieDomain: string = (UNIVERSAL_ENV.RP_COOKIE_DOMAIN ?? null) as string;
if (isServer && rpInternalApiCookieDomain == null) {
    throw new Error("env: RP_COOKIE_DOMAIN is not defined for server environment");
}
/**
 * Client variables
 */
// PLANS_URL - defines current plans-web site used to fetch plans static files (client side)
export const plansUrl: string | null = UNIVERSAL_ENV.PLANS_URL ?? null;
// BIG_DATA_API_URL - defines BigData API url to fetch statistics data
export const bigDataApiUrl: string | null = UNIVERSAL_ENV.BIG_DATA_API_URL ?? null;
// GTM_ID - defines Google Tag Manager ID for production and staging environment
export const gtmId: string = UNIVERSAL_ENV.GTM_ID;
// GOOGLE_RECAPTCHA_V3_ENV - determines id for staging/production version of captcha
export const googleRecaptchaId: string | null = UNIVERSAL_ENV.GOOGLE_RECAPTCHA_V3_ID ?? null;
// BUGSNAG_RELEASE_STAGE — define release stage, where only "production" and "staging" are reported
export const bugsnagReleaseStage: string = UNIVERSAL_ENV.BUGSNAG_RELEASE_STAGE ?? "development";
// BUGSNAG_PERFORMANCE_KEY — define key for bugsnag performance monitor tool
export const bugsnagPerformanceKey: string = UNIVERSAL_ENV.BUGSNAG_PERFORMANCE_KEY ?? null;
// SMART AD SERVER - Defines if "smartad server" is used on stage env
export const isSmartAdServerStageEnv = UNIVERSAL_ENV.SMARTAD_SERVER_STAGE_ENV === "true";
// USER_COM_ID - define user.com ID. Scripts should be initialized only if this is defined.
export const userComId: string | undefined = UNIVERSAL_ENV.USER_COM_ID;
// USER_COM_APP_SUBDOMAIN - define user.com app subdomain. Scripts should be initialized only if this is defined.
export const userComAppSubdomain: string | undefined = UNIVERSAL_ENV.USER_COM_APP_SUBDOMAIN;
// USER_COM_B2B_ID - define user.com ID. Scripts should be initialized only if this is defined.
export const userComB2BId: string | undefined = UNIVERSAL_ENV.USER_COM_B2B_ID;
// USER_COM_B2B_APP_SUBDOMAIN - define user.com app subdomain. Scripts should be initialized only if this is defined.
export const userComB2BAppSubdomain: string | undefined = UNIVERSAL_ENV.USER_COM_B2B_APP_SUBDOMAIN;
// ALGOLYTICS_ID - define algolytics ID used to report events to big data. When null algolytics won't be used
export const algolyticsId: string | undefined = UNIVERSAL_ENV.ALGOLYTICS_ID;
// SHOW_ALGOLYTICS_LOGS - enable logs for Algolytics events - not for use in production
export const showAlgolyticsLogs: boolean = UNIVERSAL_ENV.SHOW_ALGOLYTICS_LOGS === "true";
// ECHO_API_URL - defines Echo API url to post application data
export const echoApiUrl: string | null = UNIVERSAL_ENV.ECHO_API_URL ?? null;
// POI_API_URL - defines POI API url to fetch POI data
export const poiApiUrl: string | null = UNIVERSAL_ENV.POI_API_URL ?? null;
// MS_STORE_JS_SERVER_API_URL - defines ms-store-js microservice server API url
export const msStoreJsServerApiUrl: string | null = UNIVERSAL_ENV.MS_STORE_JS_SERVER_API_URL ?? null;
// KM_URL - defines url to "Konto Mieszkaniowe" app
export const kmUrl: string | null = UNIVERSAL_ENV.KM_URL ?? null;
// RP_USER_API_URL - defines RP server user API url
export const rpServerUserApiUrl: string | null = UNIVERSAL_ENV.RP_SERVER_USER_API_URL ?? null;
// ENABLE_RUM - enables Real User Monitoring
export const enableRUM = UNIVERSAL_ENV.ENABLE_RUM === "true";
// DISABLE_SESSION_SYNC - defines if session sync should be disabled
export const disableSessionSync: boolean = UNIVERSAL_ENV.DISABLE_SESSION_SYNC === "true";
export const osmTileUrl: string | null = UNIVERSAL_ENV.OSM_TILE_URL ?? null;
if (isServer && osmTileUrl === null) {
    throw new Error("env: OSM_TILE_URL is not defined for server environment");
}
// OSM_PUBLIC_TILE_URL - defines URL to public OSM tile server
export const osmPublicTileUrl: string | null = UNIVERSAL_ENV.OSM_PUBLIC_TILE_URL ?? null;
export const osmApiUrl: string | null = UNIVERSAL_ENV.OSM_API_URL ?? null;

export const weglotApiKey: string | null = UNIVERSAL_ENV.WEGLOT_API_KEY ?? null;
export const rpFeatureFlagPlanner2dPropertyAttributes: boolean = UNIVERSAL_ENV.RP_FEATURE_FLAG_PLANNER_PROPERTY_ATTRIBUTES === "true";
export const rpFeatureFlagNewUserFlow: boolean = UNIVERSAL_ENV.RP_FEATURE_FLAG_NEW_USER_FLOW === "true";
// ENABLE_LONG_CACHE - allows to have shorter cache on dev env
export const enableLongCache = UNIVERSAL_ENV.ENABLE_LONG_CACHE === "true";

// ONE_TAP_CLIENT - defines client for google one tap login
export const oneTapClient = UNIVERSAL_ENV.ONE_TAP_CLIENT ?? null;

export const browserInitialEnv = {
    RP_COMMON_URL: rpCommonUrl,
    RP_COOKIE_DOMAIN: rpInternalApiCookieDomain,
    RP_SERVER_V1_API_URL: rpServerV1ApiUrl,
    RP_SERVER_GLUE_API_URL: rpServerGlueApiUrl,
    RP_SERVER_INTERNAL_API_URL: rpServerInternalApiUrl,
    RP_SERVER_V2_API_URL: rpServerV2ApiUrl,
    RP_CLIENT_API_URL: rpClientApiUrl,
    BIG_DATA_API_URL: bigDataApiUrl,
    ECHO_API_URL: echoApiUrl,
    PLANS_URL: plansUrl,
    GTM_ID: gtmId,
    GOOGLE_RECAPTCHA_V3_ID: googleRecaptchaId,
    SMARTAD_SERVER_STAGE_ENV: UNIVERSAL_ENV.SMARTAD_SERVER_STAGE_ENV,
    USER_COM_ID: userComId,
    USER_COM_APP_SUBDOMAIN: userComAppSubdomain,
    USER_COM_B2B_ID: userComB2BId,
    USER_COM_B2B_APP_SUBDOMAIN: userComB2BAppSubdomain,
    BUGSNAG_RELEASE_STAGE: bugsnagReleaseStage,
    BUGSNAG_PERFORMANCE_KEY: bugsnagPerformanceKey,
    ALGOLYTICS_ID: algolyticsId,
    POI_API_URL: poiApiUrl,
    MS_STORE_JS_SERVER_API_URL: msStoreJsServerApiUrl,
    SHOW_ALGOLYTICS_LOGS: UNIVERSAL_ENV.SHOW_ALGOLYTICS_LOGS,
    KM_URL: kmUrl,
    RP_SERVER_USER_API_URL: rpServerUserApiUrl,
    DISABLE_SESSION_SYNC: UNIVERSAL_ENV.DISABLE_SESSION_SYNC,
    OSM_TILE_URL: osmTileUrl,
    OSM_PUBLIC_TILE_URL: osmPublicTileUrl,
    OSM_API_URL: osmApiUrl,
    WEGLOT_API_KEY: weglotApiKey,
    VALIDATE_AB_TEST_DOMAIN: UNIVERSAL_ENV.VALIDATE_AB_TEST_DOMAIN,
    RP_FEATURE_FLAG_PLANNER_PROPERTY_ATTRIBUTES: UNIVERSAL_ENV.RP_FEATURE_FLAG_PLANNER_PROPERTY_ATTRIBUTES,
    RP_FEATURE_FLAG_NEW_USER_FLOW: UNIVERSAL_ENV.RP_FEATURE_FLAG_NEW_USER_FLOW,
    ONE_TAP_CLIENT: oneTapClient,
    ENABLE_RUM: UNIVERSAL_ENV.ENABLE_RUM
};
/**
 * Server environment will read process.env to read config values
 * Client environment will read window.__INITIAL_ENV__ rendered by server html-index-generator
 *
 * WARNING: do not use `webpack.DefinePlugin` in server build as this renders values in place of `process.env` reference which prevents the server code to read system environment variables
 */
const ENV = process.env;
// NODE_ENV - defines production build and production code execution
export const isProduction: boolean = ENV.NODE_ENV === "production";
export const isDevelopment: boolean = ENV.NODE_ENV === "development";
if (!isProduction && !isDevelopment) {
    throw new Error("env: NODE_ENV is not properly defined");
}
// EXEC_ENV - defines execution environment (browser or server)
export const isBrowser: boolean = ENV.EXEC_ENV === "browser";
export const isServer: boolean = ENV.EXEC_ENV === "server";
if (!isBrowser && !isServer) {
    throw new Error("env: EXEC_ENV is not properly defined");
}

declare global {
    interface Window {
        __INITIAL_ENV__: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

const UNIVERSAL_ENV = isBrowser ? window.__INITIAL_ENV__ : process.env;

/**
 * Build image environment
 */
export const buildImageTag: string = UNIVERSAL_ENV.BUILD_IMAGE_TAG;
/**
 * Main RP variables
 */
// VALIDATE_AB_TEST_DOMAIN - Enable or disable ab test domain validation
export const validateAbTestDomain: boolean = UNIVERSAL_ENV.VALIDATE_AB_TEST_DOMAIN === "true";

// RP_COMMON_URL - defines current RP site used to display full URL
export const rpCommonUrl: string = (UNIVERSAL_ENV.RP_COMMON_URL ?? null) as string;
if (rpCommonUrl == null) {
    throw new Error("env: RP_COMMON_URL is not defined");
}
// RP_SERVER_V1_API_URL - defines current RP API site used to fetch backend data for path /api/:endpoint
export const rpServerV1ApiUrl: string = (UNIVERSAL_ENV.RP_SERVER_V1_API_URL ?? null) as string;
if (isServer && rpServerV1ApiUrl == null) {
    throw new Error("env: RP_SERVER_V1_API_URL is not defined for server environment");
}
// RP_SERVER_GLUE_API_URL - defines current RP API site used to fetch backend data for path /api/s/v2/:endpoint
export const rpServerGlueApiUrl: string = (UNIVERSAL_ENV.RP_SERVER_GLUE_API_URL ?? null) as string;
if (isServer && rpServerGlueApiUrl == null) {
    throw new Error("env: RP_SERVER_GLUE_API_URL is not defined for server environment");
}
// RP_SERVER_V2_API_URL - defines current RP API site used to fetch backend data for path /api/v2/:endpoint
export const rpServerV2ApiUrl: string = (UNIVERSAL_ENV.RP_SERVER_V2_API_URL ?? null) as string;
if (isServer && rpServerV2ApiUrl == null) {
    throw new Error("env: RP_SERVER_V2_API_URL is not defined for server environment");
}
// RP_SERVER_INTERNAL_API_URL - defines current RP internal API site used to fetch backend data from special internal API (optimized execution)
export const rpServerInternalApiUrl: string = (UNIVERSAL_ENV.RP_SERVER_INTERNAL_API_URL ?? null) as string;
if (isServer && rpServerInternalApiUrl == null) {
    throw new Error("env: RP_SERVER_INTERNAL_API_URL is not defined for server environment");
}
// RP_SERVER_MP_API_URL - defines current MP (marketplace) API site used to fetch backend data for path /api/marketplace/:endpoint
export const rpServerMpApiUrl: string = (UNIVERSAL_ENV.RP_SERVER_MP_API_URL ?? null) as string;
if (isServer && rpServerMpApiUrl == null) {
    throw new Error("env: RP_SERVER_MP_API_URL is not defined for server environment");
}
// RP_CLIENT_API_URL - defines current RP API site used to fetch backend data (client side)
export const rpClientApiUrl: string | null = UNIVERSAL_ENV.RP_CLIENT_API_URL ?? null;
// RP_COOKIE_DOMAIN - defines cookie domain to add while fetching data internally (rpbi does not provide its own cookie domain)
export const rpInternalApiCookieDomain: string = (UNIVERSAL_ENV.RP_COOKIE_DOMAIN ?? null) as string;
if (isServer && rpInternalApiCookieDomain == null) {
    throw new Error("env: RP_COOKIE_DOMAIN is not defined for server environment");
}
/**
 * Client variables
 */
// PLANS_URL - defines current plans-web site used to fetch plans static files (client side)
export const plansUrl: string | null = UNIVERSAL_ENV.PLANS_URL ?? null;
// BIG_DATA_API_URL - defines BigData API url to fetch statistics data
export const bigDataApiUrl: string | null = UNIVERSAL_ENV.BIG_DATA_API_URL ?? null;
// GTM_ID - defines Google Tag Manager ID for production and staging environment
export const gtmId: string = UNIVERSAL_ENV.GTM_ID;
// GOOGLE_RECAPTCHA_V3_ENV - determines id for staging/production version of captcha
export const googleRecaptchaId: string | null = UNIVERSAL_ENV.GOOGLE_RECAPTCHA_V3_ID ?? null;
// BUGSNAG_RELEASE_STAGE — define release stage, where only "production" and "staging" are reported
export const bugsnagReleaseStage: string = UNIVERSAL_ENV.BUGSNAG_RELEASE_STAGE ?? "development";
// BUGSNAG_PERFORMANCE_KEY — define key for bugsnag performance monitor tool
export const bugsnagPerformanceKey: string = UNIVERSAL_ENV.BUGSNAG_PERFORMANCE_KEY ?? null;
// SMART AD SERVER - Defines if "smartad server" is used on stage env
export const isSmartAdServerStageEnv = UNIVERSAL_ENV.SMARTAD_SERVER_STAGE_ENV === "true";
// USER_COM_ID - define user.com ID. Scripts should be initialized only if this is defined.
export const userComId: string | undefined = UNIVERSAL_ENV.USER_COM_ID;
// USER_COM_APP_SUBDOMAIN - define user.com app subdomain. Scripts should be initialized only if this is defined.
export const userComAppSubdomain: string | undefined = UNIVERSAL_ENV.USER_COM_APP_SUBDOMAIN;
// USER_COM_B2B_ID - define user.com ID. Scripts should be initialized only if this is defined.
export const userComB2BId: string | undefined = UNIVERSAL_ENV.USER_COM_B2B_ID;
// USER_COM_B2B_APP_SUBDOMAIN - define user.com app subdomain. Scripts should be initialized only if this is defined.
export const userComB2BAppSubdomain: string | undefined = UNIVERSAL_ENV.USER_COM_B2B_APP_SUBDOMAIN;
// ALGOLYTICS_ID - define algolytics ID used to report events to big data. When null algolytics won't be used
export const algolyticsId: string | undefined = UNIVERSAL_ENV.ALGOLYTICS_ID;
// SHOW_ALGOLYTICS_LOGS - enable logs for Algolytics events - not for use in production
export const showAlgolyticsLogs: boolean = UNIVERSAL_ENV.SHOW_ALGOLYTICS_LOGS === "true";
// ECHO_API_URL - defines Echo API url to post application data
export const echoApiUrl: string | null = UNIVERSAL_ENV.ECHO_API_URL ?? null;
// POI_API_URL - defines POI API url to fetch POI data
export const poiApiUrl: string | null = UNIVERSAL_ENV.POI_API_URL ?? null;
// MS_STORE_JS_SERVER_API_URL - defines ms-store-js microservice server API url
export const msStoreJsServerApiUrl: string | null = UNIVERSAL_ENV.MS_STORE_JS_SERVER_API_URL ?? null;
// KM_URL - defines url to "Konto Mieszkaniowe" app
export const kmUrl: string | null = UNIVERSAL_ENV.KM_URL ?? null;
// RP_USER_API_URL - defines RP server user API url
export const rpServerUserApiUrl: string | null = UNIVERSAL_ENV.RP_SERVER_USER_API_URL ?? null;
// ENABLE_RUM - enables Real User Monitoring
export const enableRUM = UNIVERSAL_ENV.ENABLE_RUM === "true";
// DISABLE_SESSION_SYNC - defines if session sync should be disabled
export const disableSessionSync: boolean = UNIVERSAL_ENV.DISABLE_SESSION_SYNC === "true";
export const osmTileUrl: string | null = UNIVERSAL_ENV.OSM_TILE_URL ?? null;
if (isServer && osmTileUrl === null) {
    throw new Error("env: OSM_TILE_URL is not defined for server environment");
}
// OSM_PUBLIC_TILE_URL - defines URL to public OSM tile server
export const osmPublicTileUrl: string | null = UNIVERSAL_ENV.OSM_PUBLIC_TILE_URL ?? null;
export const osmApiUrl: string | null = UNIVERSAL_ENV.OSM_API_URL ?? null;

export const weglotApiKey: string | null = UNIVERSAL_ENV.WEGLOT_API_KEY ?? null;
export const rpFeatureFlagPlanner2dPropertyAttributes: boolean = UNIVERSAL_ENV.RP_FEATURE_FLAG_PLANNER_PROPERTY_ATTRIBUTES === "true";
export const rpFeatureFlagNewUserFlow: boolean = UNIVERSAL_ENV.RP_FEATURE_FLAG_NEW_USER_FLOW === "true";
// ENABLE_LONG_CACHE - allows to have shorter cache on dev env
export const enableLongCache = UNIVERSAL_ENV.ENABLE_LONG_CACHE === "true";

// ONE_TAP_CLIENT - defines client for google one tap login
export const oneTapClient = UNIVERSAL_ENV.ONE_TAP_CLIENT ?? null;

export const browserInitialEnv = {
    RP_COMMON_URL: rpCommonUrl,
    RP_COOKIE_DOMAIN: rpInternalApiCookieDomain,
    RP_SERVER_V1_API_URL: rpServerV1ApiUrl,
    RP_SERVER_GLUE_API_URL: rpServerGlueApiUrl,
    RP_SERVER_INTERNAL_API_URL: rpServerInternalApiUrl,
    RP_SERVER_V2_API_URL: rpServerV2ApiUrl,
    RP_CLIENT_API_URL: rpClientApiUrl,
    BIG_DATA_API_URL: bigDataApiUrl,
    ECHO_API_URL: echoApiUrl,
    PLANS_URL: plansUrl,
    GTM_ID: gtmId,
    GOOGLE_RECAPTCHA_V3_ID: googleRecaptchaId,
    SMARTAD_SERVER_STAGE_ENV: UNIVERSAL_ENV.SMARTAD_SERVER_STAGE_ENV,
    USER_COM_ID: userComId,
    USER_COM_APP_SUBDOMAIN: userComAppSubdomain,
    USER_COM_B2B_ID: userComB2BId,
    USER_COM_B2B_APP_SUBDOMAIN: userComB2BAppSubdomain,
    BUGSNAG_RELEASE_STAGE: bugsnagReleaseStage,
    BUGSNAG_PERFORMANCE_KEY: bugsnagPerformanceKey,
    ALGOLYTICS_ID: algolyticsId,
    POI_API_URL: poiApiUrl,
    MS_STORE_JS_SERVER_API_URL: msStoreJsServerApiUrl,
    SHOW_ALGOLYTICS_LOGS: UNIVERSAL_ENV.SHOW_ALGOLYTICS_LOGS,
    KM_URL: kmUrl,
    RP_SERVER_USER_API_URL: rpServerUserApiUrl,
    DISABLE_SESSION_SYNC: UNIVERSAL_ENV.DISABLE_SESSION_SYNC,
    OSM_TILE_URL: osmTileUrl,
    OSM_PUBLIC_TILE_URL: osmPublicTileUrl,
    OSM_API_URL: osmApiUrl,
    WEGLOT_API_KEY: weglotApiKey,
    VALIDATE_AB_TEST_DOMAIN: UNIVERSAL_ENV.VALIDATE_AB_TEST_DOMAIN,
    RP_FEATURE_FLAG_PLANNER_PROPERTY_ATTRIBUTES: UNIVERSAL_ENV.RP_FEATURE_FLAG_PLANNER_PROPERTY_ATTRIBUTES,
    RP_FEATURE_FLAG_NEW_USER_FLOW: UNIVERSAL_ENV.RP_FEATURE_FLAG_NEW_USER_FLOW,
    ONE_TAP_CLIENT: oneTapClient,
    ENABLE_RUM: UNIVERSAL_ENV.ENABLE_RUM
};
