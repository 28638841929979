import {appendQueryString, getExternalRequest} from "@pg-mono/request";
import {apiBigDataLink} from "@pg-mono/rp-api-routes";

import {bigDataApiUrl} from "../../app/read_rp_environment_variables";
import {cityWithEstimatedPriceStats, districtsWithEstimatedPriceStats} from "../../property/utils/estimated_price_utils";
import {IFetchPropertyEstimatedPriceRequestParams} from "../types/IFetchPropertyEstimatedPriceRequestParams";
import {ISelectedRegionEstimatedPrice} from "../types/ISelectedRegionEstimatedPrice";
import {RegionType} from "../types/RegionType";
import {getMonthStatsPayloadDate} from "../utils/get_month_stats_payload_date";

export interface IRegionParam {
    id: number;
    type: RegionType;
}

export const getRegionQueryParams = (region: IRegionParam): Partial<IFetchPropertyEstimatedPriceRequestParams> | null => {
    switch (region.type) {
        case RegionType.DISTRICT:
            const districtRecord = districtsWithEstimatedPriceStats.get(region.id) ?? null;

            return districtRecord
                ? {
                      slug_city: districtRecord.city,
                      slug_district: districtRecord.district
                  }
                : null;

        case RegionType.CITY:
            const slugCity = cityWithEstimatedPriceStats.get(region.id) ?? null;
            return slugCity
                ? {
                      slug_city: slugCity
                  }
                : null;

        default:
            return null;
    }
};

export const fetchRegionEstimatedPrice = (region: IRegionParam): Promise<ISelectedRegionEstimatedPrice[]> => {
    const regionParams = getRegionQueryParams(region);

    if (!regionParams) {
        return Promise.resolve([]);
    }

    const fetchWithDistrict = region.type === RegionType.DISTRICT;

    const query: IFetchPropertyEstimatedPriceRequestParams = {
        ...regionParams,
        ...getMonthStatsPayloadDate(),
        scenario: "rp-price-avg"
    };

    const endpointLink = fetchWithDistrict ? apiBigDataLink.monthDistrictsStats.base : apiBigDataLink.monthCityStats.base;
    const url = appendQueryString(endpointLink(bigDataApiUrl), {...query});

    return getExternalRequest({}, url, 5000);
};
import {appendQueryString, getExternalRequest} from "@pg-mono/request";
import {apiBigDataLink} from "@pg-mono/rp-api-routes";

import {bigDataApiUrl} from "../../app/read_rp_environment_variables";
import {cityWithEstimatedPriceStats, districtsWithEstimatedPriceStats} from "../../property/utils/estimated_price_utils";
import {IFetchPropertyEstimatedPriceRequestParams} from "../types/IFetchPropertyEstimatedPriceRequestParams";
import {ISelectedRegionEstimatedPrice} from "../types/ISelectedRegionEstimatedPrice";
import {RegionType} from "../types/RegionType";
import {getMonthStatsPayloadDate} from "../utils/get_month_stats_payload_date";

export interface IRegionParam {
    id: number;
    type: RegionType;
}

export const getRegionQueryParams = (region: IRegionParam): Partial<IFetchPropertyEstimatedPriceRequestParams> | null => {
    switch (region.type) {
        case RegionType.DISTRICT:
            const districtRecord = districtsWithEstimatedPriceStats.get(region.id) ?? null;

            return districtRecord
                ? {
                      slug_city: districtRecord.city,
                      slug_district: districtRecord.district
                  }
                : null;

        case RegionType.CITY:
            const slugCity = cityWithEstimatedPriceStats.get(region.id) ?? null;
            return slugCity
                ? {
                      slug_city: slugCity
                  }
                : null;

        default:
            return null;
    }
};

export const fetchRegionEstimatedPrice = (region: IRegionParam): Promise<ISelectedRegionEstimatedPrice[]> => {
    const regionParams = getRegionQueryParams(region);

    if (!regionParams) {
        return Promise.resolve([]);
    }

    const fetchWithDistrict = region.type === RegionType.DISTRICT;

    const query: IFetchPropertyEstimatedPriceRequestParams = {
        ...regionParams,
        ...getMonthStatsPayloadDate(),
        scenario: "rp-price-avg"
    };

    const endpointLink = fetchWithDistrict ? apiBigDataLink.monthDistrictsStats.base : apiBigDataLink.monthCityStats.base;
    const url = appendQueryString(endpointLink(bigDataApiUrl), {...query});

    return getExternalRequest({}, url, 5000);
};
