import {useEffect, useState} from "react";

import {RequestState} from "@pg-mono/request-state";

import {cityWithEstimatedPriceStats, districtsWithEstimatedPriceStats} from "../../property/utils/estimated_price_utils";
import {fetchRegionEstimatedPrice} from "../api/fetch_region_estimated_price";
import {Country} from "../types/Country";
import {ISelectedRegionEstimatedPrice} from "../types/ISelectedRegionEstimatedPrice";
import {RegionType} from "../types/RegionType";

export interface IRegionEstimatedPriceSummary {
    avgPriceM2Min?: number;
    avgPriceM2Max?: number;
}

interface IParams {
    region?: {
        country: Country;
        full_name: string;
        id: number;
        stats?: {
            region_type_city?: {id: number} | null;
            region_type_county?: {id: number} | null;
            region_type_district?: {id: number} | null;
            region_type_town?: {id: number} | null;
            region_type_voivodeship?: {id: number} | null;
        };
        type: RegionType;
    };
}

export const useRegionEstimatedPrice = (params: IParams) => {
    const [requestState, setRequestState] = useState<RequestState>(RequestState.None);
    const [regionEstimatedPriceRaw, setRegionEstimatedPriceRaw] = useState<ISelectedRegionEstimatedPrice[]>([]);
    const [regionEstimatedPriceSummary, setRegionEstimatedPriceSummary] = useState<IRegionEstimatedPriceSummary>({});

    useEffect(() => {
        const region = getRegionToFetch(params?.region);

        if (!region) {
            return;
        }

        setRequestState(RequestState.Waiting);
        fetchRegionEstimatedPrice(region)
            .then((response) => {
                setRegionEstimatedPriceRaw(response);

                const avgPricesM2 = response.map((item) => item.avg_price_m2).filter(Boolean);
                setRegionEstimatedPriceSummary({
                    avgPriceM2Min: Math.min(...avgPricesM2),
                    avgPriceM2Max: Math.max(...avgPricesM2)
                });
                setRequestState(RequestState.Success);
            })
            .catch(() => {
                setRegionEstimatedPriceRaw([]);
                setRegionEstimatedPriceSummary({
                    avgPriceM2Min: 0,
                    avgPriceM2Max: 0
                });
                setRequestState(RequestState.Error);
            });
    }, [params.region?.id, params.region?.type]);

    return {
        requestState,
        regionEstimatedPriceSummary,
        regionEstimatedPriceRaw
    };
};

export const getRegionToFetch = (region?: IParams["region"]) => {
    if (region?.stats?.region_type_district?.id && districtsWithEstimatedPriceStats.get(region.stats.region_type_district.id)) {
        return {
            id: region.stats.region_type_district?.id,
            type: RegionType.DISTRICT
        };
    }

    if (region?.stats?.region_type_city?.id && cityWithEstimatedPriceStats.get(region.stats.region_type_city.id)) {
        return {
            id: region.stats.region_type_city?.id,
            type: RegionType.CITY
        };
    }

    return null;
};
import {useEffect, useState} from "react";

import {RequestState} from "@pg-mono/request-state";

import {cityWithEstimatedPriceStats, districtsWithEstimatedPriceStats} from "../../property/utils/estimated_price_utils";
import {fetchRegionEstimatedPrice} from "../api/fetch_region_estimated_price";
import {Country} from "../types/Country";
import {ISelectedRegionEstimatedPrice} from "../types/ISelectedRegionEstimatedPrice";
import {RegionType} from "../types/RegionType";

export interface IRegionEstimatedPriceSummary {
    avgPriceM2Min?: number;
    avgPriceM2Max?: number;
}

interface IParams {
    region?: {
        country: Country;
        full_name: string;
        id: number;
        stats?: {
            region_type_city?: {id: number} | null;
            region_type_county?: {id: number} | null;
            region_type_district?: {id: number} | null;
            region_type_town?: {id: number} | null;
            region_type_voivodeship?: {id: number} | null;
        };
        type: RegionType;
    };
}

export const useRegionEstimatedPrice = (params: IParams) => {
    const [requestState, setRequestState] = useState<RequestState>(RequestState.None);
    const [regionEstimatedPriceRaw, setRegionEstimatedPriceRaw] = useState<ISelectedRegionEstimatedPrice[]>([]);
    const [regionEstimatedPriceSummary, setRegionEstimatedPriceSummary] = useState<IRegionEstimatedPriceSummary>({});

    useEffect(() => {
        const region = getRegionToFetch(params?.region);

        if (!region) {
            return;
        }

        setRequestState(RequestState.Waiting);
        fetchRegionEstimatedPrice(region)
            .then((response) => {
                setRegionEstimatedPriceRaw(response);

                const avgPricesM2 = response.map((item) => item.avg_price_m2).filter(Boolean);
                setRegionEstimatedPriceSummary({
                    avgPriceM2Min: Math.min(...avgPricesM2),
                    avgPriceM2Max: Math.max(...avgPricesM2)
                });
                setRequestState(RequestState.Success);
            })
            .catch(() => {
                setRegionEstimatedPriceRaw([]);
                setRegionEstimatedPriceSummary({
                    avgPriceM2Min: 0,
                    avgPriceM2Max: 0
                });
                setRequestState(RequestState.Error);
            });
    }, [params.region?.id, params.region?.type]);

    return {
        requestState,
        regionEstimatedPriceSummary,
        regionEstimatedPriceRaw
    };
};

export const getRegionToFetch = (region?: IParams["region"]) => {
    if (region?.stats?.region_type_district?.id && districtsWithEstimatedPriceStats.get(region.stats.region_type_district.id)) {
        return {
            id: region.stats.region_type_district?.id,
            type: RegionType.DISTRICT
        };
    }

    if (region?.stats?.region_type_city?.id && cityWithEstimatedPriceStats.get(region.stats.region_type_city.id)) {
        return {
            id: region.stats.region_type_city?.id,
            type: RegionType.CITY
        };
    }

    return null;
};
