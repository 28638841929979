import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum CreditCalculatorGTMEvent {
    CREDIT_INSTALMENT_CALCULATOR = "credit_calculator",
    CREDIT_WORTHINESS_CALCULATOR = "credit_score_calculator",
    CREDIT_RATING_CALCULATOR = "credit_ability_calculator"
}

export enum CreditCalculatorViewActions {
    OFFER_VIEW = "offer_view",
    CALCULATOR_VIEW = "calculator_view",
    ASK = "offer_ask_click"
}

export enum CreditCalculatorInstalmentEventAction {
    PERIOD = "credit_time",
    CONTRIBUTION = "payment",
    PRICE = "property_price",
    CALL = "credit_call"
}

export enum CreditCalculatorWorthinessEventAction {
    CALL = "score_call",
    APPLICANTS = "score_applicant",
    PROPERTY_TYPE = "score_property",
    AGE = "score_age",
    PERIOD = "score_time",
    AMOUNT = "score_amount",
    EARNINGS = "score_earnings"
}

export enum CreditCalculatorListingActions {
    FILTERS_ACCEPT = "credit_calculation",
    SHOW_DETAILS = "credit_offer_info_on",
    HIDE_DETAILS = "credit_offer_info_off"
}
export enum CreditRatingCalculatorEventAction {
    CALL = "credit_ability_call",
    APPLICANTS = "rating_applicant",
    PROPERTY_TYPE = "rating_property",
    AGE = "user_age",
    AVERAGE_EARNINGS = "earnings",
    HOUSEHOLD_EXPENSES = "expenses",
    LOAN_INSTALLMENTS = "credit_payment"
}

export type AllCreditGtmEventActions =
    | CreditCalculatorInstalmentEventAction
    | CreditCalculatorWorthinessEventAction
    | CreditCalculatorViewActions
    | CreditRatingCalculatorEventAction;

const creditCalculatorEvent = (event: CreditCalculatorGTMEvent, data: Record<string, string | number> = {}) => {
    hitGoogleTagManager({event, ...data});
};

export const creditCalculatorAnalytics = {
    gtm: {
        creditCalculatorEvent
    }
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum CreditCalculatorGTMEvent {
    CREDIT_INSTALMENT_CALCULATOR = "credit_calculator",
    CREDIT_WORTHINESS_CALCULATOR = "credit_score_calculator",
    CREDIT_RATING_CALCULATOR = "credit_ability_calculator"
}

export enum CreditCalculatorViewActions {
    OFFER_VIEW = "offer_view",
    CALCULATOR_VIEW = "calculator_view",
    ASK = "offer_ask_click"
}

export enum CreditCalculatorInstalmentEventAction {
    PERIOD = "credit_time",
    CONTRIBUTION = "payment",
    PRICE = "property_price",
    CALL = "credit_call"
}

export enum CreditCalculatorWorthinessEventAction {
    CALL = "score_call",
    APPLICANTS = "score_applicant",
    PROPERTY_TYPE = "score_property",
    AGE = "score_age",
    PERIOD = "score_time",
    AMOUNT = "score_amount",
    EARNINGS = "score_earnings"
}

export enum CreditCalculatorListingActions {
    FILTERS_ACCEPT = "credit_calculation",
    SHOW_DETAILS = "credit_offer_info_on",
    HIDE_DETAILS = "credit_offer_info_off"
}
export enum CreditRatingCalculatorEventAction {
    CALL = "credit_ability_call",
    APPLICANTS = "rating_applicant",
    PROPERTY_TYPE = "rating_property",
    AGE = "user_age",
    AVERAGE_EARNINGS = "earnings",
    HOUSEHOLD_EXPENSES = "expenses",
    LOAN_INSTALLMENTS = "credit_payment"
}

export type AllCreditGtmEventActions =
    | CreditCalculatorInstalmentEventAction
    | CreditCalculatorWorthinessEventAction
    | CreditCalculatorViewActions
    | CreditRatingCalculatorEventAction;

const creditCalculatorEvent = (event: CreditCalculatorGTMEvent, data: Record<string, string | number> = {}) => {
    hitGoogleTagManager({event, ...data});
};

export const creditCalculatorAnalytics = {
    gtm: {
        creditCalculatorEvent
    }
};
